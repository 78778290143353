import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box } from "@material-ui/core";

import { LayoutMenu } from "containers";

import { usePageMenuStyles } from "./PageMenu.styles";

export const PageMenu = ({ children, className, seo }) => {
  const styles = usePageMenuStyles();

  return (
    <LayoutMenu seo={seo}>
      <Box className={classNames(styles.page, className)} component="main">
        {children}
      </Box>
    </LayoutMenu>
  );
};

PageMenu.defaultProps = {
  className: undefined,
  seo: undefined,
};

PageMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  seo: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        name: PropTypes.string,
        property: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }),
};
