import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";

import { Link } from "components";

import { useCardProjectStyles } from "./CardProject.styles";

export const CardProject = ({
  category,
  className,
  description,
  image,
  path,
  title,
}) => {
  const styles = useCardProjectStyles();

  return (
    <Card
      className={classNames(styles.card, className)}
      elevation={1}
      variant="outlined"
    >
      <CardActionArea component={Link} to={path}>
        <Img
          alt={title}
          className={styles.image}
          fluid={image.childImageSharp.fluid}
        />
        <CardContent className={styles.content}>
          {category && (
            <Typography
              className={styles.category}
              component="h3"
              variant="body2"
            >
              {category}
            </Typography>
          )}
          <Typography className={styles.title} component="h3" variant="body1">
            {title}
          </Typography>
          {description && (
            <Typography
              className={styles.description}
              component="h5"
              variant="body2"
            >
              {description}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardProject.defaultProps = {
  category: undefined,
  className: undefined,
  description: undefined,
};

CardProject.propTypes = {
  category: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }).isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
