import React from "react";
import classNames from "classnames";
import { Box, Container } from "@material-ui/core";

import { useSectionStyles } from "./Section.styles";
import { sectionPropTypes } from "../_config";

export const Section = ({
  alignContent,
  alignItems,
  bgcolor,
  children,
  className,
  color,
  component,
  containerProps,
  justifyContent,
  minHeight,
  ...restSectionProps
}) => {
  const styles = useSectionStyles();

  return (
    <Box
      alignContent={alignContent}
      alignItems={alignItems}
      bgcolor={bgcolor}
      className={classNames(styles.section, className)}
      color={color}
      component={component}
      justifyContent={justifyContent}
      minHeight={minHeight}
      {...restSectionProps}
    >
      <Container {...containerProps} className={containerProps.className}>
        {children}
      </Container>
    </Box>
  );
};

Section.defaultProps = {
  className: null,
  component: "section",
  containerProps: {
    maxWidth: "md",
  },
};

Section.propTypes = sectionPropTypes;
