import { makeStyles } from "@material-ui/core/styles";

export const useGridStyles = makeStyles(({ breakpoints }) => ({
  grid: {
    display: "grid",
    gridTemplateRows: "auto",
    [breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    },
  },
}));
