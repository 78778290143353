import * as yup from "yup";

/**
 * capitalizeString - capitlizes the first letter of a string.
 *
 * @param {String} string - string to transform
 */
export const capitalizeString = (string) => {
  if (typeof string !== "string") return "";

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * contactFormSchema - schema used to validate contact form.
 */
export const contactFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address. ")
    .required("We need an email address to contact you."),
  description: yup
    .string()
    .min(5, "Describe why you're contacting us in at least 5 characters. ")
    .required("Please leave a reason for contacting us."),
  name: yup
    .string()
    .min(3, "Come on, a name is usually longer than that. ")
    .required("What should we call you?"),
});

/**
 * deleteEmptyValues - delete values to clear form data from empty strings.
 */
export const deleteEmptyValues = (values) => {
  let noEmptyValues = {};
  Object.keys(values).forEach((value) => {
    if (values[value]) {
      noEmptyValues[value] = values[value];
    }
  });

  return noEmptyValues;
};

/**
 * encodeFormData - encode form data for submussion to netlify forms.
 */
export const encodeFormData = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

/**
 * formatBlogPosts - format posts edges array for blog page posts seo
 *
 * @param {Array} posts - array with blog post objects
 */
export const formatBlogPosts = (posts, url) => {
  if (!posts.edges.length) return null;

  const formattedPosts = posts.edges.map(
    ({
      node: {
        frontmatter: { title, image },
      },
    }) => ({
      headline: title,
      image: `${url}${image?.childImageSharp?.fluid?.src}`,
    })
  );

  return formattedPosts;
};

/**
 * getCategory - gets category from an array of with category prop.
 *
 * @param {Object} object - object with edges array
 */
export const getCategory = (object) => {
  if (!object) return null;

  const category = object.edges
    .map(
      ({
        node: {
          frontmatter: { category },
        },
      }) => category
    )
    .reduce((category) => category);

  return category;
};

/**
 * getCategortyFilters - get page filters using provided categories group.
 *
 * @param {Array} categories - categories to map
 * @param {String} pathPrefix - optional path prefix
 * @param {Object} buttonOptions - options to configure the all categories button
 */
export const getCategoryFilters = (
  categories,
  pathPrefix = "",
  allCategoriesButton = {
    id: "all-categories",
    label: "All categories",
  }
) => {
  const categoryButtons = categories.group.map(({ category }) => ({
    id: category.toLowerCase(),
    label: category,
    path: `${pathPrefix}/${category.split(" ").join("-").toLowerCase()}/`,
  }));

  const categoryFilters = [
    {
      path: pathPrefix,
      ...allCategoriesButton,
    },
    ...categoryButtons,
  ];

  return categoryFilters;
};

/**
 * getCategoryPath - get a category path using a category string.
 *
 * @param {String} string - string to convert to url
 * @param {String} pathPrefix - optional path prefix
 */
export const getCategoryPath = (string, pathPrefix = "") => {
  if (!string) return "/";

  return `${pathPrefix}/${string.split(" ").join("-").toLowerCase()}/`;
};

/**
 * getFormFields - reduce fields array to object with name prop keys set to object.
 *
 * @param {Array} fields - fields array to reduce must contain name key
 */
export const getFormFields = (fields) =>
  fields.reduce((field, key) => ({ ...field, [key.name]: key }), {});

/**
 * getInitialFormValues - reduce fields array to object with name prop keys set to empty.
 *
 * @param {Array} fields - fields array to reduce must contain name key
 */
export const getInitialFormValues = (fields) =>
  fields.reduce((field, key) => ({ ...field, [key.name]: "" }), {});

/**
 * getServicesWithCategories - combines services and serviceCategories data
 *
 * @param {Object} services - services object with edges array
 * @param {Object} serviceCategories - serviceCategories object with group array
 */
export const getServicesWithCategories = (services, serviceCategories) => {
  if (!services && !serviceCategories) return null;

  const servicesWithCategories = services.edges.map(
    ({
      node: {
        fields: { slug },
        frontmatter: { image, service },
        id,
      },
    }) => {
      const categories = serviceCategories.group
        .map((category) => service === category.service && category.edges)
        .filter(Boolean)
        .flat();

      return {
        categories,
        id,
        image,
        service,
        slug,
      };
    }
  );

  return servicesWithCategories;
};
