import { makeStyles } from "@material-ui/core/styles";

export const useHeroFilterStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    button: {
      marginRight: spacing(2),
      marginTop: spacing(2),
      transition: "border-color 250ms ease-in-out, color 250ms ease-in-out",
      "&.active": {
        borderColor: palette.primary.main,
        color: palette.primary.main,
        pointerEvents: "none",
      },
      "&:last-child": {
        marginRight: 0,
      },
      "&:hover": {
        textDecoration: "none",
      },
      ".primary &": {
        borderColor: palette.common.white,
        color: palette.common.white,
        "&.active": {
          borderColor: palette.secondary.main,
          color: palette.secondary.main,
          pointerEvents: "none",
        },
      },
      ".secondary &": {
        borderColor: palette.text.secondary,
        color: palette.text.secondary,
        "&.active": {
          borderColor: palette.text.primary,
          color: palette.text.primary,
          pointerEvents: "none",
        },
      },
    },
    buttons: {
      alignItems: "center",
      display: "flex",
      flexFlow: "row wrap",
      marginBottom: spacing(4),
      marginTop: spacing(4),
    },
    context: {
      fontWeight: typography.fontWeightRegular,
      marginTop: spacing(1),
      maxWidth: breakpoints.values.md,
    },
    heading: {
      fontWeight: typography.fontWeightRegular,
      marginTop: spacing(12),
      maxWidth: breakpoints.values.md,
    },
    hero: {
      alignItems: "flex-end",
      padding: spacing(10, 0),
    },
  })
);
