import { makeStyles } from "@material-ui/core/styles";

export const useCardImageStyles = makeStyles(({ palette }) => ({
  card: {
    alignItems: "center",
    backgroundColor: palette.primary.dark,
    borderColor: palette.primary.dark,
    borderRadius: 0,
    display: "block",
    overflow: "hidden",
    position: "relative",
  },
}));
