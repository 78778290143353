import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box } from "@material-ui/core";

import { useGridStyles } from "./Grid.styles";

export const Grid = ({ children, className }) => {
  const styles = useGridStyles();

  return <Box className={classNames(styles.grid, className)}>{children}</Box>;
};

Grid.defaultProps = {
  className: undefined,
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
