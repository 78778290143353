import { makeStyles } from "@material-ui/core/styles";

export const useLayoutFooterStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    backlink: {
      display: "block",
      marginBottom: spacing(1),
      "& a": {
        color: palette.primary.light,
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    brand: {
      color: palette.common.white,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    contact: {
      color: palette.primary.light,
      display: "block",
      textDecoration: "none",
      transition: "250ms color ease-in-out",
      "&:hover": {
        color: palette.common.white,
        textDecoration: "underline",
      },
    },
    contentContainer: {
      alignItems: "center",
      display: "flex",
    },
    copyright: {
      alignItems: "flex-end",
      padding: spacing(2, 0),
    },
    footer: {
      backgroundColor: palette.primary.dark,
      color: palette.common.white,
    },
    gridRow: {
      alignItems: "flex-start",
      padding: spacing(12, 0, 4),
    },
    link: {
      display: "none",
      [breakpoints.up("sm")]: {
        display: "block",
        marginRight: spacing(2),
      },
    },
    logo: {
      width: 142,
    },
    nav: {
      display: "flex",
      flexFlow: "row wrap",
      padding: 0,
    },
    navItem: {
      color: palette.primary.light,
      fontWeight: typography.fontWeightBold,
      marginRight: spacing(3),
      padding: spacing(1, 0),
      transition: "250ms color ease-in-out",
      width: "auto",
      "&:last-child": {
        marginRight: 0,
      },
      "&:hover": {
        color: palette.common.white,
        textDecoration: "none",
      },
    },
    navResponsive: {
      [breakpoints.up("md")]: {
        justifyContent: "flex-end",
        "& a": {
          marginLeft: spacing(3),
          marginRight: 0,
        },
      },
    },
    navRight: {
      justifyContent: "flex-end",
    },
    subtitle: {
      lineHeight: 1,
      marginBottom: spacing(1),
    },
  })
);
