// Material UI Custom Palette: https://material-ui.com/customization/palette/
module.exports = {
  primary: {
    dark: "#26388c",
    light: "#9eb3e8",
    main: "#3260ce",
  },
  secondary: {
    dark: "#0e1423",
    light: "#ffee9a",
    main: "#ffd500",
  },
  type: "light",
  text: {
    primary: "#101012",
    secondary: "#222831",
  },
  error: {
    main: "#c70039",
  },
  success: {
    main: "#639a67",
  },
  grey: {
    50: "#ececef",
    100: "#d7d7da",
    200: "#a3a5ae",
    300: "#a7a7ae",
    400: "#8d8d96",
    500: "#757584",
    600: "#5c5c70",
    700: "#505062",
    800: "#3b3b4a",
    900: "#2a2a32",
  },
  background: {
    default: "#fff",
  },
};
