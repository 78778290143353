import React from "react";
import PropTypes from "prop-types";
import { AppBar, Button, Container } from "@material-ui/core";
import { ArrowBack, MenuOpen } from "@material-ui/icons";

import { Link } from "components";

import { useLayoutHeaderStyles } from "./Header.styles";

export const LayoutHeader = ({
  bgcolor,
  button,
  children,
  className,
  color,
  isHidden,
  position,
}) => {
  const styles = useLayoutHeaderStyles();

  if (isHidden) return null;

  return (
    <AppBar
      className={className}
      color={bgcolor}
      elevation={0}
      position={position}
    >
      <Container className={styles.container} component="nav">
        {button && (
          <Button
            className={styles.button}
            color={color}
            component={Link}
            startIcon={button.label === "Menu" ? <MenuOpen /> : <ArrowBack />}
            to={button.path}
            variant="outlined"
          >
            {button.label}
          </Button>
        )}
        {children}
      </Container>
    </AppBar>
  );
};

LayoutHeader.defaultProps = {
  bgcolor: "transparent",
  button: {
    label: "Menu",
    path: "/",
  },
  children: undefined,
  className: undefined,
  color: "primary",
  isHidden: false,
  position: "absolute",
};

LayoutHeader.propTypes = {
  bgcolor: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  isHidden: PropTypes.bool,
  position: PropTypes.string,
};
