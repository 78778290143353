import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import { Card } from "@material-ui/core";

import { useCardImageStyles } from "./CardImage.styles";

export const CardImage = ({ className, image, name }) => {
  const styles = useCardImageStyles();

  return (
    <Card
      className={classNames(styles.card, className)}
      elevation={1}
      variant="outlined"
    >
      <Img alt={name} fluid={image.fluid} />
    </Card>
  );
};

CardImage.defaultProps = {
  className: undefined,
};

CardImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      sizes: PropTypes.string,
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
};
