import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { List, ListItem, Typography } from "@material-ui/core";

import { Link } from "components";

import { useListServiceCategoriesStyles } from "./ListServiceCategories.styles";

export const ListServiceCategories = ({ serviceCategories }) => {
  const styles = useListServiceCategoriesStyles();

  if (!serviceCategories) return null;

  return (
    <List className={styles.list}>
      {serviceCategories.map(({ node: { fields, frontmatter, id } }) => (
        <ListItem className={styles.item} key={id}>
          <Link className={styles.link} to={fields.slug}>
            {frontmatter.image && (
              <Img
                alt={frontmatter.title}
                className={styles.img}
                fluid={frontmatter.image.childImageSharp.fluid}
              />
            )}
            <Typography className={styles.category} component="h2" variant="h6">
              {frontmatter.category}
            </Typography>
          </Link>
        </ListItem>
      ))}
    </List>
  );
};

ListServiceCategories.propTypes = {
  serviceCategories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        fields: PropTypes.shape({
          slug: PropTypes.string,
        }),
        frontmatter: PropTypes.shape({
          category: PropTypes.string,
          draft: PropTypes.bool,
          image: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                sizes: PropTypes.string,
              }),
            }),
          }),
          order: PropTypes.number,
          title: PropTypes.string,
        }),
        id: PropTypes.string,
      }),
    })
  ),
};
