import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import {
  Card as MuiCard,
  CardActionArea,
  CardContent,
  Hidden,
  Typography,
} from "@material-ui/core";

import { Link } from "components";

import { useCardPostStyles } from "./CardPost.styles";

export const CardPost = ({
  category,
  className,
  date,
  description,
  image,
  path,
  title,
}) => {
  const styles = useCardPostStyles();

  return (
    <MuiCard className={classNames(styles.card, className)} variant="outlined">
      <CardActionArea className={styles.link} component={Link} to={path}>
        <Hidden lgUp>
          <CardContent
            className={classNames(styles.content, styles.header)}
            component="header"
          >
            <Typography variant="body2">
              Posted in {category} {date && `on ${date}`}
            </Typography>
            <Typography className={styles.title} component="h3" variant="h6">
              {title}
            </Typography>
          </CardContent>
        </Hidden>
        {image && (
          <CardContent className={classNames(styles.content, "image")}>
            <Img
              alt={title}
              className={styles.image}
              fluid={image.childImageSharp.fluid}
            />
          </CardContent>
        )}
        <CardContent className={styles.content}>
          <Hidden mdDown>
            <Typography variant="body2">
              Posted in {category} {date && `on ${date}`}
            </Typography>
            <Typography className={styles.title} component="h3" variant="h6">
              {title}
            </Typography>
          </Hidden>
          {description && (
            <>
              <Typography
                className={styles.description}
                component="p"
                variant="body1"
              >
                {description}
              </Typography>
              <Typography className={styles.cta}>Read more</Typography>
            </>
          )}
        </CardContent>
      </CardActionArea>
    </MuiCard>
  );
};

CardPost.propTypes = {
  author: PropTypes.string,
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }),
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
