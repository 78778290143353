import { makeStyles } from "@material-ui/core/styles";

export const useCardTestimonialStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    card: {
      alignItems: "center",
      backgroundColor: palette.grey[50],
      color: palette.text.primary,
      display: "flex",
      marginBottom: spacing(4),
      marginTop: spacing(4),
    },
    content: {
      flex: 1,
      padding: spacing(4),
      [breakpoints.up("md")]: {
        padding: spacing(4, 8),
      },
    },
    heading: {
      color: palette.primary.main,
      marginBottom: spacing(2),
      marginTop: spacing(1),
    },
    meta: {
      display: "block",
      fontStyle: "normal",
      marginBottom: spacing(2),
      marginTop: spacing(2),
    },
    testimonial: {
      fontWeight: typography.fontWeightMedium,
    },
  })
);
