import { makeStyles } from "@material-ui/core/styles";

export const useSectionSplitStyles = makeStyles(({ breakpoints, spacing }) => ({
  grid: {
    position: "relative",
  },
  gridLeft: {
    ".form &": {
      margin: "0 auto",
    },
    ".form.left &": {
      marginLeft: 0,
      maxWidth: 800,
      [breakpoints.up("lg")]: {
        flex: "none",
      },
      [breakpoints.up("xl")]: {
        marginLeft: -300,
      },
    },
    ".form.right &": {
      [breakpoints.up("lg")]: {
        flex: 1,
        maxWidth: "none",
      },
    },
  },
  gridRight: {
    ".form &": {
      margin: "0 auto",
    },
    ".form.left &": {
      [breakpoints.up("lg")]: {
        flex: 1,
        maxWidth: "none",
      },
    },
    ".form.right &": {
      marginRight: 0,
      maxWidth: 800,
      [breakpoints.up("lg")]: {
        flex: "none",
      },
      [breakpoints.up("xl")]: {
        marginRight: -300,
      },
    },
  },
  section: {
    margin: "auto",
    position: "relative",
    zIndex: 1,
  },
}));
