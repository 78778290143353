import React from "react";
import PropTypes from "prop-types";
import { Card as MuiCard, CardContent, Typography } from "@material-ui/core";

import { useCardTestimonialStyles } from "./CardTestimonial.styles";

export const CardTestimonial = ({ client, context, date, heading }) => {
  const styles = useCardTestimonialStyles();

  return (
    <MuiCard className={styles.card} elevation={1} variant="outlined">
      <CardContent className={styles.content}>
        {heading && (
          <Typography className={styles.heading} component="h6" variant="h4">
            {heading}
          </Typography>
        )}
        <Typography
          className={styles.testimonial}
          component="blockquote"
          variant="subtitle1"
        >
          "{context}"
        </Typography>
        <Typography className={styles.meta} component="cite" variant="body1">
          -- {client}
          {date && `, ${date}`}
        </Typography>
      </CardContent>
    </MuiCard>
  );
};

CardTestimonial.propTypes = {
  client: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  date: PropTypes.string,
  heading: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};
