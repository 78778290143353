import React, { useState } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import { Box, Hidden, List, ListItem, Typography } from "@material-ui/core";
import { ArrowRightAlt, ChevronRight } from "@material-ui/icons";

import { Link } from "components";

import { useListServicesStyles } from "./ListServices.styles";

// TODO:
// - refactor this into a services container or a services section

export const ListServices = ({ hideCategories, services }) => {
  const styles = useListServicesStyles();
  const [activePreview, setActivePreview] = useState(null);

  if (!services) return null;

  return (
    <List className={styles.list} component="nav">
      {services.map(({ categories, id, image, service, slug }, index) => (
        <ListItem className={styles.item} key={id}>
          <Hidden smDown>
            <Box
              className={classNames(styles.preview, {
                active: activePreview === index,
              })}
            >
              <Img
                alt={service}
                className={styles.img}
                fluid={image.childImageSharp.fluid}
              />
            </Box>
          </Hidden>
          <Typography
            className={styles.link}
            component={Link}
            to={slug}
            variant="h4"
            onMouseEnter={() => setActivePreview(index)}
            onMouseLeave={() => setActivePreview(null)}
          >
            {service}
            <Hidden mdUp>
              <ChevronRight className={styles.icon} fontSize="large" />
            </Hidden>
            <Hidden smDown>
              <ArrowRightAlt className={styles.icon} fontSize="large" />
            </Hidden>
          </Typography>
          {!hideCategories && categories && categories.length > 0 && (
            <Box
              className={styles.categories}
              onMouseEnter={() => setActivePreview(index)}
              onMouseLeave={() => setActivePreview(null)}
            >
              {categories.map(({ node: { fields, frontmatter, id } }) => (
                <Typography
                  className={styles.category}
                  component={Link}
                  key={id}
                  to={fields.slug}
                  variant="h6"
                >
                  {frontmatter.category}
                  <Hidden mdUp>
                    <ChevronRight className={styles.icon} fontSize="large" />
                  </Hidden>
                </Typography>
              ))}
            </Box>
          )}
        </ListItem>
      ))}
    </List>
  );
};

ListServices.defaultProps = {
  hideCategories: false,
  services: null,
};

ListServices.propTypes = {
  hideCategories: PropTypes.bool,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              category: PropTypes.string,
              draft: PropTypes.bool,
              order: PropTypes.number,
              type: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
      id: PropTypes.string,
      image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
            sizes: PropTypes.string,
          }),
        }),
      }),
      service: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
};
