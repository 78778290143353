module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-125010979-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Clean Commercial","start_url":"/","background_color":"#fff","theme_color":"#3260ce","display":"minimal-ui","icon":"/opt/build/repo/data/assets/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"574f6e3cd999770f4a8b058a76b2d78f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-images","options":{"maxWidth":1280,"maxHeight":720,"linkImagesToOriginal":false}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"canonical":"https://cleancommercial.co","description":"We service the Las Vegas valley in plumbing, electrical, heating & air-conditioning, carpentry & framing, remodeling & new construction, pool decks, pressure washing, epoxy work and more.","htmlAttributes":{"prefix":"og: https://ogp.me/ns#"},"language":"en","openGraph":{"description":"We service the Las Vegas valley in plumbing, electrical, heating & air-conditioning, carpentry & framing, remodeling & new construction, pool decks, pressure washing, epoxy work and more.","images":[{"url":"https://cleancommercial.co/logo.png","width":800,"height":800,"alt":"Clean Commercial"}],"locale":"en_IE","site_name":"Clean Commercial","title":"Residential and Commercial Services from Skilled Artisans & Engineers in Las Vegas","type":"website","url":"https://cleancommercial.co"},"twitter":{"handle":"@las_vegas_clean","site":"@las_vegas_clean","cardType":"summary_large_image"},"title":"Residential and Commercial Services from Skilled Artisans & Engineers in Las Vegas","titleTemplate":"Clean Commercial | %s"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
