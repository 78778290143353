import React from "react";
import classNames from "classnames";

import { Hero } from "components";

import { useHeroHeaderStyles } from "./HeroHeader.styles";
import { heroPropTypes } from "../_config";

export const HeroHeader = ({
  buttonsProps,
  children,
  className,
  headingProps,
  ...restHeroProps
}) => {
  const styles = useHeroHeaderStyles();

  return (
    <Hero
      {...restHeroProps}
      buttonsProps={{
        ...buttonsProps,
        className: classNames(styles.buttons, buttonsProps.className),
      }}
      className={classNames(styles.hero, className)}
      headingProps={{
        ...headingProps,
        className: classNames(styles.heading, headingProps.className),
      }}
    >
      {children}
    </Hero>
  );
};

HeroHeader.defaultProps = {
  alignItems: "flex-end",
  buttonsProps: {
    justifyContent: "flext-start",
  },
  children: null,
  className: null,
  component: "header",
  context: null,
  contextProps: {
    component: "h2",
    variant: "h6",
  },
  heading: null,
  headingProps: {
    component: "h1",
    variant: "h3",
  },
  minHeight: "30vh",
};

HeroHeader.propTypes = heroPropTypes;
