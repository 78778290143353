import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import {
  AppBar,
  Box,
  Container,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { Link } from "components";

import { useLayoutMenuHeaderStyles } from "./MenuHeader.styles";

export const LayoutMenuHeader = ({ brand, contact, logo, routes }) => {
  const styles = useLayoutMenuHeaderStyles();

  return (
    <AppBar color="primary" elevation={0} position="relative">
      <Grid className={styles.container} container component={Container}>
        <Grid className={styles.title} item xs={12} md={6}>
          {logo && (
            <Img
              alt={brand}
              className={styles.logo}
              fluid={logo.childImageSharp.fluid}
            />
          )}
          <Box className={styles.context}>
            <Typography className={styles.brand} component="h1" variant="h4">
              {brand}
            </Typography>
            {contact && (
              <>
                <Typography
                  className={styles.contact}
                  component={OutboundLink}
                  href={`tel:${contact.phone}`}
                  variant="body2"
                >
                  {contact.phone}
                </Typography>
                <Typography
                  className={styles.contact}
                  component={OutboundLink}
                  href={`mailto:${contact.email}`}
                  variant="body2"
                >
                  {contact.email}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid className={styles.nav} component="nav" item xs={12} md={6}>
            {routes.map((route) => (
              <Typography
                className={styles.link}
                component={Link}
                key={route.id}
                to={route.path}
                variant="h6"
              >
                {route.label}
              </Typography>
            ))}
          </Grid>
        </Hidden>
      </Grid>
    </AppBar>
  );
};

LayoutMenuHeader.propTypes = {
  brand: PropTypes.string.isRequired,
  contact: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  logo: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      hash: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
};
