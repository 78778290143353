import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { CardImage, Grid } from "components";

import { useGridImagesStyles } from "./GridImages.styles";

// TODO: create ImagesGrid container to render image grid
// - pass regex to filter images
// - active image modal feature can be disabled
// - set different grid styles using classNames

export const GridImages = ({ children, className, images }) => {
  const styles = useGridImagesStyles();

  return (
    <Grid className={classNames(styles.grid, className)}>
      {images
        ? images.edges.map(({ node: { id, image, name } }) => (
            <CardImage
              className={styles.cell}
              image={image}
              key={id}
              name={name}
            />
          ))
        : children}
    </Grid>
  );
};

GridImages.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  images: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string,
          image: PropTypes.shape({
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number,
              base64: PropTypes.string,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
          name: PropTypes.string,
          relativeDirectory: PropTypes.string,
        }),
      })
    ),
  }),
};
