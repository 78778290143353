import { makeStyles } from "@material-ui/core/styles";

export const useCardPostStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    card: {
      color: palette.text.primary,
      display: "flex",
      transition: "border-color 250ms ease-in-out",
      "&:hover": {
        borderColor: palette.primary.light,
      },
    },
    content: {
      flex: 1,
      padding: spacing(3),
      "&.image": {
        flex: "1 100%",
        padding: 0,
        [breakpoints.up("lg")]: {
          display: "block",
          flex: "none",
          padding: spacing(3),
          paddingRight: 0,
        },
      },
    },
    cta: {
      color: palette.primary.main,
      fontWeight: typography.fontWeightMedium,
      lineHeight: 1,
      marginTop: spacing(2),
      transition: "color 250ms ease-in-out",
      "a:hover &": {
        color: palette.primary.dark,
      },
    },
    description: {
      color: palette.grey[600],
      marginTop: spacing(1),
    },
    header: {
      display: "block",
    },
    image: {
      maxHeight: 300,
      width: "100%",
      [breakpoints.up("md")]: {
        maxHeight: 250,
      },
      [breakpoints.up("lg")]: {
        borderRadius: 2,
        maxHeight: "auto",
        width: 250,
      },
    },
    link: {
      backgroundColor: palette.common.white,
      display: "flex",
      flexFlow: "row wrap",
      "&:hover": {
        textDecoration: "none",
      },
    },
    title: {
      color: palette.text.primary,
      transition: "color 250ms ease-in-out",
      "a:hover &": {
        alignItems: "center",
        color: palette.primary.main,
      },
    },
  })
);
