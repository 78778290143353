import { makeStyles } from "@material-ui/core/styles";

export const useHeroStyles = makeStyles(({ spacing, typography }) => ({
  button: {
    marginRight: spacing(2),
    "&:last-child": {
      marginRight: 0,
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  buttons: {
    display: "flex",
    marginBottom: spacing(4),
    marginTop: spacing(8),
  },
  container: {
    zIndex: 1,
  },
  context: {
    fontWeight: typography.fontWeightRegular,
    marginBottom: spacing(2),
    marginTop: spacing(2),
  },
  heading: {
    fontWeight: typography.fontWeightBold,
    marginBottom: spacing(2),
    marginTop: spacing(8),
  },
  hero: {
    display: "flex",
    overflow: "hidden",
    padding: spacing(8, 0),
    position: "relative",
  },
  image: {
    height: "100%",
    position: "absolute !important",
    right: 0,
    top: 0,
    width: "100%",
    "&::after": {
      background:
        "linear-gradient(90deg, rgba(80, 80, 98, 1) 0%, rgba(80, 80, 98, 0.9) 100%)",
      content: "''",
      height: "100%",
      position: "absolute",
      right: 0,
      top: 0,
      width: "100%",
    },
    "&.primary, .primary &": {
      "&::after": {
        background:
          "linear-gradient(90deg, rgba(50, 96, 206, 1) 0%, rgba(50, 96, 206, 0.9) 100%)",
      },
    },
    "&.secondary, .secondary &": {
      "&::after": {
        background:
          "linear-gradient(90deg, rgba(255, 213, 2, 1) 0%, rgba(255, 213, 2, 0.9) 100%)",
      },
    },
    "&.clear": {
      "&::after": {
        content: "none",
      },
    },
  },
}));
