import { makeStyles } from "@material-ui/core/styles";

export const useCardServiceStyles = makeStyles(
  ({ palette, spacing, typography }) => ({
    card: {
      alignItems: "center",
      borderColor: palette.secondary.dark,
      borderRadius: 0,
      display: "block",
      overflow: "hidden",
      position: "relative",
      transition: "border-color 250ms ease-in-out",
      "&:hover, &:focus": {
        borderColor: palette.secondary.main,
        textDecoration: "none",
      },
    },
    category: {
      color: palette.common.white,
      fontWeight: typography.fontWeightMedium,
      letterSpacing: 1,
      lineHeight: 1.25,
      textShadow: `1px 1px 1px ${palette.secondary.dark}`,
      textTransform: "uppercase",
      transition: "color 250ms ease-in-out",
      "a:hover &, a:focus &": {
        color: palette.secondary.main,
      },
    },
    content: {
      background:
        "radial-gradient(circle, rgba(50, 96, 206, 0.15) 0%, rgba(50, 96, 206, 0.15) 100%)",
      bottom: 0,
      color: palette.common.white,
      display: "flex",
      flexFlow: "column nowrap",
      height: "100%",
      justifyContent: "center",
      padding: spacing(2),
      position: "absolute",
      width: "100%",
      textAlign: "center",
      transition: "background 250ms ease-in-out, color 250ms ease-in-out",
      "&:last-child": {
        paddingBottom: spacing(1.5),
      },
      "a:hover &, a:focus &": {
        background:
          "radial-gradient(circle, rgba(50, 96, 206, 0.5) 0%, rgba(50, 96, 206, 0.15) 100%)",
        color: palette.secondary.main,
      },
    },
    image: {
      transition: "transform 250ms ease-in-out",
      "a:hover &, a:focus &": {
        transform: "scale3d(1.1, 1.1, 1)",
      },
    },
  })
);
