import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Form } from "react-final-form";
import { TextField, makeValidate } from "mui-rff";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import { StaticQuery, graphql } from "gatsby";

import { useFormStyles } from "components";
import {
  contactFormSchema,
  deleteEmptyValues,
  encodeFormData,
  getFormFields,
  getInitialFormValues,
} from "utils";

// TODO:
// - do something with referralProps (i.e. analytics tracking, mailchimp tags, etc...)
// - use custom recaptcha field (and send g-recaptcha value to netlify)
// - move constants to `_config` file
// - props to configure form styles (or use className)
// - create serverless functions to submit form data to mailchimp api - https://dev.to/peterj/mailchimp-api-netlify-and-serverless-functions-4j7m

const subscription = {
  submitting: true,
  pristine: true,
};

const validate = makeValidate(contactFormSchema);

export const FormContact = ({
  buttonCopy,
  className,
  context,
  headerProps,
  heading,
  messageFail,
  messagePass,
  messagePending,
  paperProps,
  referralProps,
}) => {
  const styles = useFormStyles();
  const [botField, setBotField] = useState("");
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState("info");

  const onBotFieldChange = (event) => setBotField(event.target.value);

  return (
    <StaticQuery
      query={graphql`
        query ContactFormQuery {
          form: formsYaml(id: { eq: "FormContact" }) {
            form {
              button
              fields {
                helperText
                label
                name
                required
                type
              }
              messages {
                fail
                pass
                pending
              }
              method
              name
            }
            id
          }
        }
      `}
      render={({
        form: {
          form: { button, fields, messages, method, name },
        },
      }) => {
        const field = getFormFields(fields);
        const initialValues = getInitialFormValues(fields);
        const formHeaders = {
          "Content-Type": "application/x-www-form-urlencoded",
        };

        const handleFormSubmit = async (values, form) => {
          // TODO: add these fields to initialValues - can just pass values then)
          const cleanData = deleteEmptyValues({
            "bot-field": botField,
            "referral-category": referralProps.category,
            "referral-page": referralProps.page,
            "referral-type": referralProps.type,
            ...values,
          });
          const encodedData = encodeFormData({
            "form-name": name,
            ...cleanData,
          });
          let message = messagePending || messages.pending;
          setMessage(message);
          fetch("/", {
            method: method,
            headers: formHeaders,
            body: encodedData,
          })
            .then((response) => {
              message = `${response.statusText && `${response.statusText} - `}${
                messagePass || messages.pass
              }`;
              // Adding these timeouts for ux
              // - gives user chance to read messages while form submits
              // - gives off the perception that form data is being processed in background
              setTimeout(() => {
                resetFormFields(values, form);
                setStatus("success");
                setMessage(message);
                setTimeout(() => {
                  form.reset();
                  setStatus("info");
                  setMessage("");
                }, 2500);
              }, 1000);
            })
            .catch((error) => {
              message = `${error.statusText && `${error.statusText} - `}${
                messageFail || messages.fail
              }`;
              setStatus("error");
              setMessage(message);
            });
        };

        // Field state needs to be reset (because form reset doesn't do that that for some reason)
        const resetFormFields = (values, { change, resetFieldState }) =>
          Object.keys(values).forEach((key) => {
            change(key, undefined);
            resetFieldState(key);
          });

        return (
          <Form
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            subscription={subscription}
            validate={validate}
          >
            {({ handleSubmit, submitting }) => (
              <Box
                className={classNames(styles.form, className)}
                component="form"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                // data-netlify-recaptcha="true"
                name={name}
                noValidate={true}
                onSubmit={handleSubmit}
              >
                {(context || heading) && (
                  <Box
                    {...headerProps}
                    className={classNames(styles.header, headerProps.className)}
                    component="header"
                  >
                    {heading && (
                      <Typography
                        className={styles.heading}
                        component="h3"
                        variant="h4"
                      >
                        {heading}
                      </Typography>
                    )}
                    {context && (
                      <Typography
                        className={styles.context}
                        component="h6"
                        variant="subtitle1"
                      >
                        {context}
                      </Typography>
                    )}
                  </Box>
                )}
                <Paper
                  {...paperProps}
                  className={classNames(styles.paper, paperProps.className)}
                >
                  <TextField
                    className={classNames(styles.field, "hidden")}
                    name="form-name"
                    type="hidden"
                    value={name}
                  />
                  <TextField
                    className={classNames(styles.field, "hidden")}
                    name="bot-field"
                    type="hidden"
                    value={botField}
                    onChange={onBotFieldChange}
                  />
                  <TextField
                    className={classNames(styles.field, "hidden")}
                    name="referral-category"
                    type="hidden"
                    value={referralProps.category}
                  />
                  <TextField
                    className={classNames(styles.field, "hidden")}
                    name="referral-page"
                    type="hidden"
                    value={referralProps.page}
                  />
                  <TextField
                    className={classNames(styles.field, "hidden")}
                    name="referral-type"
                    type="hidden"
                    value={referralProps.type}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        {...field.name}
                        className={styles.field}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...field.email}
                        className={styles.field}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...field.phone}
                        className={styles.field}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...field.description}
                        className={styles.field}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={12}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Grid className={styles.footer} container component="footer">
                  <Grid item xs={12} md={6} lg={7}>
                    {message && (
                      <Typography
                        className={classNames(styles.message, status)}
                        component="p"
                        variant="body1"
                      >
                        {message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={5}>
                    <Button
                      className={styles.button}
                      color="primary"
                      disabled={submitting}
                      endIcon={<ArrowRightAlt />}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {buttonCopy || button}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Form>
        );
      }}
    />
  );
};

FormContact.defaultProps = {
  buttonCopy: null,
  className: null,
  headerProps: {
    className: null,
  },
  messageFail: null,
  messagePass: null,
  messagePending: null,
  paperProps: {
    className: null,
    component: "section",
    elevation: 1,
    variant: "outlined",
  },
  referralProps: {
    category: "",
    page: "/contact/",
    type: "",
  },
};

FormContact.propTypes = {
  buttonCopy: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    form: PropTypes.shape({
      form: PropTypes.shape({
        buttons: PropTypes.string,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            helperText: PropTypes.string,
            label: PropTypes.string,
            name: PropTypes.string,
            required: PropTypes.bool,
            type: PropTypes.string,
          })
        ),
        messages: PropTypes.shape({
          fail: PropTypes.string,
          pass: PropTypes.string,
          pending: PropTypes.string,
        }),
        method: PropTypes.string,
        name: PropTypes.string,
      }),
      id: PropTypes.string,
    }),
  }),
  headerProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  messageFail: PropTypes.string,
  messagePass: PropTypes.string,
  messagePending: PropTypes.string,
  paperProps: PropTypes.shape({
    className: PropTypes.string,
    component: PropTypes.elementType,
    elevation: PropTypes.number,
    variant: PropTypes.oneOf(["elevation", "outlined"]),
  }), // paperProps: https://material-ui.com/api/paper/#props
  referralProps: PropTypes.shape({
    category: PropTypes.string,
    page: PropTypes.string,
    type: PropTypes.string,
  }),
};
