import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { StaticQuery, graphql } from "gatsby";

import { SEO } from "components";

import { LayoutFooter, LayoutHeader } from "./components";

import { useLayoutStyles } from "./Layout.styles";

export const Layout = ({ children, footer, header, main, seo }) => {
  const styles = useLayoutStyles();

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          logo: file(relativePath: { eq: "assets/logo.png" }) {
            childImageSharp {
              fluid(maxHeight: 200, maxWidth: 200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          site {
            siteMetadata {
              backlink
              contact {
                email
                phone
              }
              copyright
              name
              routes {
                id
                label
                path
              }
              slogan
              socialMedia {
                instagram
                twitter
              }
            }
          }
        }
      `}
      render={({
        logo,
        site: {
          siteMetadata: {
            backlink,
            contact,
            copyright,
            name,
            routes,
            slogan,
            socialMedia,
          },
        },
      }) => (
        <>
          <SEO
            {...seo}
            titleTemplate={seo?.title ? `%s | ${name}` : `${name} | %s`}
          />
          <Box className={styles.layout}>
            <LayoutHeader {...header} />
            {children}
            <LayoutFooter
              backlink={backlink}
              contact={contact}
              copyright={copyright}
              logo={logo.childImageSharp.fluid}
              name={name}
              socialMedia={socialMedia}
              routes={routes}
              slogan={slogan}
              {...footer}
            />
          </Box>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    logo: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        backlink: PropTypes.string,
        contact: PropTypes.shape({
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
        copyright: PropTypes.string,
        defaultDescription: PropTypes.string,
        defaultTitle: PropTypes.string,
        name: PropTypes.string,
        routes: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            path: PropTypes.string,
          })
        ),
        siteUrl: PropTypes.string,
        slogan: PropTypes.string,
        socialMedia: PropTypes.shape({
          instagram: PropTypes.string,
          twitter: PropTypes.string,
        }),
      }),
    }),
  }),
  footer: PropTypes.shape({
    bgcolor: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    isHidden: PropTypes.bool,
  }),
  header: PropTypes.shape({
    bgcolor: PropTypes.string,
    button: PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    }),
    className: PropTypes.string,
    color: PropTypes.string,
    isHidden: PropTypes.bool,
    position: PropTypes.string,
  }),
  main: PropTypes.shape({
    bgcolor: PropTypes.string,
    component: PropTypes.elementType,
  }), // main: https://material-ui.com/system/basics/#all-inclusive
  seo: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
    }),
    keywords: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        name: PropTypes.string,
        property: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }),
};
