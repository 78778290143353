import React from "react";
import classNames from "classnames";

import { Hero } from "components";

import { useHeroServiceStyles } from "./HeroService.styles";
import { heroPropTypes } from "../_config";

export const HeroService = ({
  children,
  className,
  contextProps,
  headingProps,
  ...restHeroProps
}) => {
  const styles = useHeroServiceStyles();

  return (
    <Hero
      {...restHeroProps}
      className={classNames(styles.hero, className)}
      contextProps={{
        ...contextProps,
        className: classNames(styles.context, contextProps.className),
      }}
      headingProps={{
        ...headingProps,
        className: classNames(styles.heading, headingProps.className),
      }}
      imageClassName="secondary"
    >
      {children}
    </Hero>
  );
};

HeroService.defaultProps = {
  alignItems: "flex-end",
  children: null,
  className: null,
  component: "header",
  containerProps: {
    maxWidth: "lg",
  },
  contextProps: {
    component: "h2",
    variant: "h6",
  },
  headingProps: {
    component: "h1",
    variant: "h2",
  },
  minHeight: "40vh",
};

HeroService.propTypes = heroPropTypes;
