import { makeStyles } from "@material-ui/core/styles";

export const useHeroProjectStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    button: {
      borderColor: palette.common.white,
      color: palette.common.white,
      marginRight: spacing(2),
      marginTop: spacing(2),
      transition: "border-color 250ms ease-in-out, color 250ms ease-in-out",
      "&.active": {
        borderColor: palette.secondary.main,
        color: palette.secondary.main,
        pointerEvents: "none",
      },
      "&:last-child": {
        marginRight: 0,
      },
      "&:hover": {
        textDecoration: "none",
      },
    },
    buttons: {
      alignItems: "center",
      display: "flex",
      flexFlow: "row wrap",
      marginBottom: spacing(4),
      marginTop: spacing(4),
    },
    container: {
      zIndex: 1,
    },
    context: {
      fontWeight: typography.fontWeightRegular,
      marginTop: spacing(1),
      maxWidth: breakpoints.values.md,
    },
    heading: {
      marginTop: spacing(12),
      maxWidth: breakpoints.values.md,
    },
    hero: {
      alignItems: "flex-end",
      display: "flex",
      overflow: "hidden",
      padding: spacing(10, 0),
      position: "relative",
    },
    image: {
      height: "100%",
      position: "absolute !important",
      right: 0,
      top: 0,
      width: "100%",
      "&::after": {
        background:
          "linear-gradient(90deg, rgba(50, 96, 206, 1) 0%, rgba(50, 96, 206, 0.9) 100%)",
        content: "''",
        height: "100%",
        position: "absolute",
        right: 0,
        top: 0,
        width: "100%",
      },
    },
  })
);
