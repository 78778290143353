import { makeStyles } from "@material-ui/core/styles";

export const useListServicesStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    categories: {
      alignItems: "stretch",
      display: "flex",
      flex: 1,
      flexFlow: "column nowrap",
      paddingTop: spacing(1),
      zIndex: 1,
      [breakpoints.up("md")]: {
        alignItems: "flex-start",
        flexFlow: "row wrap",
        paddingTop: spacing(2),
      },
    },
    category: {
      alignItems: "center",
      color: palette.primary.light,
      display: "flex",
      flex: 1,
      fontWeight: typography.fontWeightRegular,
      justifyContent: "space-between",
      marginBottom: spacing(1),
      transition: "color 200ms ease-in-out",
      "&:hover": {
        color: palette.secondary.light,
        textDecoration: "none",
      },
      [breakpoints.up("md")]: {
        flex: "none",
        marginRight: spacing(3),
      },
    },
    icon: {
      marginLeft: spacing(1),
      [breakpoints.up("md")]: {
        opacity: 0,
        transform: "translateX(-5px)",
        transition: "opacity 200ms ease-in-out, transform 200ms ease-in-out",
        "a:hover &": {
          opacity: 1,
          transform: "translateX(0)",
        },
      },
    },
    img: {
      width: "75vw",
    },
    item: {
      alignItems: "stretch",
      flexFlow: "column nowrap",
      padding: spacing(1, 0),
      [breakpoints.up("md")]: {
        flex: "1 50%",
        padding: spacing(1.5, 0),
      },
    },
    link: {
      alignItems: "center",
      color: palette.common.white,
      display: "flex",
      flex: 1,
      fontWeight: typography.fontWeightMedium,
      justifyContent: "space-between",
      padding: spacing(1, 0),
      transition: "color 200ms ease-in-out, letter-spacing 200ms ease-in-out",
      zIndex: 1,
      [breakpoints.up("md")]: {
        justifyContent: "flex-start",
        padding: spacing(1.5, 0),
        "&::before": {
          backgroundColor: palette.secondary.main,
          content: "''",
          height: 3,
          opacity: 0,
          transition:
            "margin 200ms ease-in-out, opacity 200ms ease-in-out, width 200ms ease-in-out",
          width: 0,
        },
      },
      "&:hover": {
        color: palette.secondary.main,
        textDecoration: "none",
        [breakpoints.up("md")]: {
          "&::before": {
            letterSpacing: 2,
            marginRight: 15,
            opacity: 1,
            width: 16,
          },
        },
      },
    },
    list: {
      alignItems: "flex-start",
      display: "flex",
      flexFlow: "row wrap",
      margin: 0,
      padding: 0,
    },
    preview: {
      alignItems: "center",
      bottom: 100,
      display: "flex",
      justifyContent: "center",
      opacity: 0,
      position: "fixed",
      right: 0,
      transition: "opacity 200ms ease-in-out, transform 300ms ease-in-out",
      transform: "translateX(25%)",
      "&::before": {
        background:
          "linear-gradient(90deg, rgba(51,95,206,1) 0%, rgba(51,95,206,0) 100%)",
        boxShadow: "inset 80px 0 40px 60px #335fce",
        content: "''",
        height: "100%",
        position: "absolute",
        width: "100%",
        zIndex: 1,
      },
      "li:nth-child(even) &": {
        left: 0,
        right: "auto",
        transform: "translateX(-25%)",
        "&::before": {
          background:
            "linear-gradient(90deg, rgba(51,95,206,0) 0%, rgba(51,95,206,1) 100%)",
          boxShadow: "inset -80px 0 40px 60px #335fce",
        },
      },
      "&.active": {
        [breakpoints.up("md")]: {
          opacity: 0.2,
          transform: "translateX(0) !important",
        },
      },
    },
  })
);
