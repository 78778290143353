import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";
import { Box, Button, Container, Typography } from "@material-ui/core";

import { Link } from "components";

import { useHeroStyles } from "./Hero.styles";
import { heroPropTypes } from "../_config";

// TODO:
// - render different buttons depending on each button props in buttons
// - look for other ways to check if component should return null

export const Hero = ({
  alignContent,
  alignItems,
  bgcolor,
  buttonProps,
  buttons,
  buttonsFooter,
  children,
  className,
  color,
  component,
  image,
  imageClassName,
  justifyContent,
  minHeight,
  buttonsProps,
  context,
  contextProps,
  containerProps,
  heading,
  headingProps,
  ...restHeroProps
}) => {
  const styles = useHeroStyles();
  const isEmpty = !buttons && !children && !context && !heading && !image;

  if (isEmpty) return null;

  return (
    <Box
      alignContent={alignContent}
      alignItems={alignItems}
      bgcolor={bgcolor}
      className={classNames(styles.hero, className)}
      color={color}
      component={component}
      justifyContent={justifyContent}
      minHeight={minHeight}
      {...restHeroProps}
    >
      {image && (
        <Img
          alt={image.name || "hero-image"}
          className={classNames(styles.image, imageClassName)}
          fluid={image.childImageSharp.fluid}
          {...image.childImageSharp}
        />
      )}
      <Container
        {...containerProps}
        className={classNames(styles.container, containerProps.className)}
      >
        {heading && (
          <Typography
            {...headingProps}
            className={classNames(styles.heading, headingProps.className)}
            component={headingProps.component || "h1"}
            variant={headingProps.variant || "h3"}
          >
            {heading}
          </Typography>
        )}
        {context && (
          <Typography
            {...contextProps}
            className={classNames(styles.context, contextProps.className)}
            component={contextProps.component || "h2"}
            variant={contextProps.variant || "h6"}
          >
            {context}
          </Typography>
        )}
        {children}
        {buttons && buttons.length > 0 && (
          <Box
            {...buttonsProps}
            className={classNames(styles.buttons, buttonsProps.className)}
          >
            {buttons.map((button) => (
              <Button
                activeClassName="active"
                component={Link}
                to={button.path}
                {...buttonProps}
                {...button}
                className={classNames(
                  styles.button,
                  buttonProps.className,
                  button.className
                )}
                color={buttonProps.color || button.color}
                key={button.id || button.label}
                size={buttonProps.size || button.size}
                variant={buttonProps.variant || button.variant}
              >
                {button.label}
              </Button>
            ))}
            {buttonsFooter}
          </Box>
        )}
      </Container>
    </Box>
  );
};

Hero.defaultProps = {
  alignContent: "center",
  alignItems: "center",
  bgcolor: null,
  buttonProps: {
    color: "primary",
    size: "large",
    variant: "contained",
  },
  buttons: null,
  buttonsProps: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonsFooter: null,
  children: null,
  className: null,
  component: "section",
  containerProps: {
    disableGutters: false,
    fixed: false,
    maxWidth: "md",
  },
  context: null,
  contextProps: {
    className: null,
    component: "h2",
    variant: "h6",
  },
  heading: null,
  headingProps: {
    className: null,
    component: "h1",
    variant: "h3",
  },
  justifyContent: "center",
  minHeight: "50vh",
};

Hero.propTypes = heroPropTypes;
