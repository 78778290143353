import { makeStyles } from "@material-ui/core/styles";

export const useSectionErrorStyles = makeStyles(({ spacing }) => ({
  image: {
    display: "block",
    margin: "auto",
  },
  section: {
    backgroundColor: "#150f14",
    padding: spacing(10, 0),
  },
}));
