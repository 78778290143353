import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import { Layout } from "containers";

export const PageService = ({ children, className, options, seo }) => (
  <Layout {...options} seo={seo}>
    <Box className={className} component="main">
      {children}
    </Box>
  </Layout>
);

PageService.defaultProps = {
  className: undefined,
  seo: undefined,
};

PageService.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  options: PropTypes.shape({
    footer: PropTypes.shape({
      bgcolor: PropTypes.string,
      className: PropTypes.string,
      color: PropTypes.string,
      isHidden: PropTypes.bool,
    }),
    header: PropTypes.shape({
      bgcolor: PropTypes.string,
      button: PropTypes.shape({
        label: PropTypes.string,
        path: PropTypes.string,
      }),
      className: PropTypes.string,
      color: PropTypes.string,
      isHidden: PropTypes.bool,
      position: PropTypes.string,
    }),
  }),
  seo: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        name: PropTypes.string,
        property: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }),
};
