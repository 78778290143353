import { makeStyles } from "@material-ui/core/styles";

export const usePageStyles = makeStyles(({ palette, spacing }) => ({
  button: {
    marginTop: spacing(2),
    "&:hover": {
      textDecoration: "none",
    },
    "&.hero": {
      marginBottom: spacing(4),
      marginTop: spacing(4),
    },
  },
  context: {
    marginBottom: spacing(2),
  },
  heading: {
    marginBottom: spacing(3),
    marginTop: spacing(6),
  },
  link: {
    margin: spacing(0, 0.75),
    transition: "color 250ms ease-in-out",
    "&:hover": {
      color: palette.primary.dark,
    },
  },
  page: {
    flex: 1,
  },
  subtitle: {
    marginTop: spacing(2),
  },
}));
