import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { Instagram, Twitter } from "@material-ui/icons";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import { Link } from "components";

import { useLayoutFooterStyles } from "./Footer.styles";

export const LayoutFooter = ({
  backlink,
  contact,
  copyright,
  isHidden,
  logo,
  name,
  routes,
  slogan,
  socialMedia,
}) => {
  const styles = useLayoutFooterStyles();

  if (isHidden) return null;

  return (
    <Box component="footer" className={styles.footer}>
      <Container>
        <Grid className={styles.gridRow} container spacing={4}>
          <Grid className={styles.contentContainer} item xs={12} md={8}>
            {logo && (
              <Link className={styles.link} to="/">
                <Img alt={name} className={styles.logo} fluid={logo} />
              </Link>
            )}
            <Box>
              <Link className={styles.brand} to="/">
                <Typography component="h4" variant="h4">
                  {name}
                </Typography>
                {slogan && (
                  <Typography
                    className={styles.subtitle}
                    component="h4"
                    variant="subtitle1"
                  >
                    {slogan}
                  </Typography>
                )}
              </Link>
              {contact && (
                <>
                  <Typography
                    className={styles.contact}
                    component="a"
                    href={`tel:${contact.phone}`}
                    variant="subtitle2"
                  >
                    {contact.phone}
                  </Typography>
                  <Typography
                    className={styles.contact}
                    component="a"
                    href={`mailto:${contact.email}`}
                    variant="subtitle2"
                  >
                    {contact.email}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          {routes && (
            <Grid item xs={12} md={4}>
              <List
                className={classNames(styles.nav, styles.navResponsive)}
                component="nav"
              >
                {routes.map((route) => (
                  <ListItem
                    className={styles.navItem}
                    component={Link}
                    key={route.id}
                    to={route.path}
                  >
                    {route.label}
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>
        <Grid className={styles.copyright} container>
          <Grid item xs={8}>
            <Typography variant="caption">
              &copy; {new Date().getFullYear()}. {copyright}
            </Typography>
            {backlink && (
              <Typography
                className={styles.backlink}
                dangerouslySetInnerHTML={{ __html: backlink }}
                variant="caption"
              />
            )}
          </Grid>
          {socialMedia && (
            <Grid item xs={4}>
              <List
                className={classNames(styles.nav, styles.navRight)}
                component="nav"
              >
                <ListItem
                  className={styles.navItem}
                  component={OutboundLink}
                  href={`https://instagram.com/${socialMedia.instagram}`}
                  target="_blank"
                >
                  <Instagram />
                </ListItem>
                <ListItem
                  className={styles.navItem}
                  component={OutboundLink}
                  href={`https://twitter.com/${socialMedia.twitter}`}
                  target="_blank"
                >
                  <Twitter />
                </ListItem>
              </List>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

LayoutFooter.propTypes = {
  backlink: PropTypes.string,
  contact: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  copyright: PropTypes.string,
  logo: PropTypes.shape({
    aspectRatio: PropTypes.number,
    base64: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      hash: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  socialMedia: PropTypes.shape({
    instagram: PropTypes.string,
    twitter: PropTypes.string,
  }),
};
