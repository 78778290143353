import { makeStyles } from "@material-ui/core/styles";

export const useGridImagesStyles = makeStyles(({ breakpoints, spacing }) => ({
  cell: {
    "&.active": {
      gridColumn: "span 2",
      [breakpoints.up("md")]: {
        gridRow: "span 2",
      },
    },
    ".feature &": {
      [breakpoints.up("sm")]: {
        gridColumn: "span 1",
        gridRow: "span 1",
      },
      "&:nth-child(3n+1)": {
        alignSelf: "flex-start",
      },
      "&:nth-child(3n+2)": {
        alignSelf: "center",
      },
      "&:nth-child(3n+3)": {
        alignSelf: "flex-end",
      },
      "&:nth-child(3n)": {
        [breakpoints.up("xl")]: {
          gridColumn: "span 2",
          gridRow: "span 2",
        },
      },
      "&:nth-child(6n)": {
        [breakpoints.up("md")]: {
          gridColumn: "span 2",
          gridRow: "span 2",
        },
      },
      "&:first-child, &:last-child": {
        [breakpoints.up("sm")]: {
          gridColumn: "span 2",
          gridRow: "span 2",
        },
      },
    },
  },
  grid: {
    gridGap: spacing(0.5),
    margin: spacing(-6, "auto"),
    [breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
    },
    [breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(340px, 1fr))",
    },
    [breakpoints.up("xl")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(480px, 1fr))",
    },
  },
}));
