import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box } from "@material-ui/core";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { useArticleStyles } from "./Article.styles";

export const Article = ({ className, component, content }) => {
  const styles = useArticleStyles();

  return (
    <Box
      className={classNames(styles.article, className)}
      component={component}
    >
      <MDXRenderer>{content}</MDXRenderer>
    </Box>
  );
};

Article.defaultProps = {
  className: undefined,
  component: "article",
};

Article.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
  content: PropTypes.string.isRequired,
};
