import { makeStyles } from "@material-ui/core/styles";

export const useHeroMenuStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    container: {
      maxWidth: 600,
      [breakpoints.up("md")]: {
        maxWidth: 1280,
      },
    },
    context: {
      color: palette.primary.light,
      fontWeight: typography.fontWeightRegular,
      margin: "0 auto",
      maxWidth: 820,
      textAlign: "center",
      [breakpoints.up("md")]: {
        margin: 0,
        textAlign: "left",
      },
    },
    heading: {
      marginTop: spacing(4),
    },
    hero: {
      alignItems: "center",
      backgroundColor: palette.primary.main,
      color: palette.common.white,
      display: "flex",
      padding: spacing(8, 0, 3),
      [breakpoints.up("md")]: {
        paddingBottom: spacing(6),
        paddingTop: spacing(3),
      },
    },
  })
);
