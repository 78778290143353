import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Chip,
  Container,
  Hidden,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { ChevronRight, InsertEmoticon } from "@material-ui/icons";

import { Link } from "components";

import { useLayoutMenuFooterStyles } from "./MenuFooter.styles";

export const LayoutMenuFooter = ({ routes, totalCount }) => {
  const styles = useLayoutMenuFooterStyles();

  return (
    <Box component="footer" className={styles.footer}>
      <Container className={styles.container}>
        <Hidden mdUp>
          <List className={styles.nav} component="nav">
            {routes.map((route) => (
              <ListItem className={styles.item} key={route.id}>
                <Typography
                  className={styles.link}
                  component={Link}
                  to={route.path}
                  variant="h5"
                >
                  {route.label}
                  <ChevronRight className={styles.icon} fontSize="large" />
                </Typography>
              </ListItem>
            ))}
          </List>
        </Hidden>
        {totalCount && (
          <Typography
            className={styles.counter}
            component="div"
            variant="body1"
          >
            <Chip
              className={styles.chip}
              label={totalCount}
              variant="outlined"
            />
            Happy customers and counting
            <InsertEmoticon className={styles.icon} />
          </Typography>
        )}
      </Container>
    </Box>
  );
};

LayoutMenuFooter.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      hash: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  totalCount: PropTypes.number,
};
