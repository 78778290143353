import React from "react";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";

export const SEO = ({ children, json, jsonLd, ...rest }) => (
  <>
    <GatsbySeo {...rest}>{children}</GatsbySeo>
    {json && <JsonLd defer={false} json={json} />}
    {jsonLd}
  </>
);
