import { makeStyles } from "@material-ui/core/styles";

export const useLayoutStyles = makeStyles(({ palette, spacing }) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflowX: "hidden",
    "& ::selection": {
      backgroundColor: palette.primary.main,
      color: palette.secondary.main,
    },
  },
}));
