import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box, Container } from "@material-ui/core";

import { useSectionMediaStyles } from "./SectionMedia.styles";

export const SectionMedia = ({ bgcolor, className, children, component }) => {
  const styles = useSectionMediaStyles();

  return (
    <Box
      bgcolor={bgcolor}
      className={classNames(styles.section, className)}
      component={component}
    >
      <Container className={styles.container}>{children}</Container>
    </Box>
  );
};

SectionMedia.defaultProps = {
  bgcolor: "primary.dark",
  component: "section",
};

SectionMedia.propTypes = {
  bgcolor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  component: PropTypes.elementType,
};
