import { makeStyles } from "@material-ui/core/styles";

export const useLayoutMenuHeaderStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    brand: {
      color: palette.common.white,
      flex: "1 100%",
      fontWeight: typography.fontWeightMedium,
      marginBottom: spacing(1),
    },
    contact: {
      color: palette.primary.light,
      flex: "none",
      fontWeight: typography.fontWeightRegular,
      margin: spacing(0, 1.5),
      textDecoration: "none",
      transition: "color 250ms ease-in",
      "&:hover": {
        color: palette.common.white,
      },
      [breakpoints.up("md")]: {
        margin: 0,
        marginRight: spacing(3),
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    container: {
      alignContent: "center",
      alignItems: "center",
      justifyContent: "space-between",
      [breakpoints.up("md")]: {
        marginTop: spacing(4),
        maxWidth: 1600,
      },
    },
    context: {
      alignItems: "center",
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "center",
      margin: "0 auto",
      textAlign: "center",
      [breakpoints.up("md")]: {
        justifyContent: "flex-start",
        margin: 0,
        textAlign: "left",
      },
    },
    link: {
      color: palette.common.white,
      margin: spacing(0, 3),
      transition: "color 250ms ease-in",
      "&:hover": {
        color: palette.primary.light,
        textDecoration: "none",
      },
      [breakpoints.up("lg")]: {
        margin: spacing(0, 6),
      },
    },
    logo: {
      margin: spacing(4, "auto", 1),
      maxWidth: 200,
      width: "100%",
      [breakpoints.up("md")]: {
        margin: 0,
        marginLeft: spacing(-1),
        marginRight: spacing(2),
        maxWidth: 150,
      },
    },
    nav: {
      display: "flex",
      justifyContent: "flex-end",
    },
    title: {
      alignItems: "center",
      display: "flex",
      flexFlow: "column nowrap",
      [breakpoints.up("md")]: {
        flexFlow: "row nowrap",
      },
    },
  })
);
