import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box } from "@material-ui/core";

import { Layout } from "containers";

import { usePageStyles } from "./Page.styles";

export const Page = ({ children, className, options, seo }) => {
  const styles = usePageStyles();

  return (
    <Layout {...options} seo={seo}>
      <Box className={classNames(styles.page, className)} component="main">
        {children}
      </Box>
    </Layout>
  );
};

Page.defaultProps = {
  className: undefined,
  seo: undefined,
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  options: PropTypes.shape({
    footer: PropTypes.shape({
      bgcolor: PropTypes.string,
      className: PropTypes.string,
      color: PropTypes.string,
      isHidden: PropTypes.bool,
    }),
    header: PropTypes.shape({
      bgcolor: PropTypes.string,
      button: PropTypes.shape({
        label: PropTypes.string,
        path: PropTypes.string,
      }),
      className: PropTypes.string,
      color: PropTypes.string,
      isHidden: PropTypes.bool,
      position: PropTypes.string,
    }),
  }),
  seo: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
    }),
    keywords: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        name: PropTypes.string,
        property: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }),
};
