import PropTypes from "prop-types";

export const heroPropTypes = {
  alignContent: PropTypes.string,
  alignItems: PropTypes.string,
  bgcolor: PropTypes.string,
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    component: PropTypes.elementType,
    id: PropTypes.string,
    label: PropTypes.string,
    path: PropTypes.string,
  }), // buttonProps: https://material-ui.com/api/button/#props
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      component: PropTypes.elementType,
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    }) // buttonProps: https://material-ui.com/api/button/#props
  ),
  buttonsFooter: PropTypes.elementType,
  buttonsProps: PropTypes.shape({
    alignContent: PropTypes.string,
    alignItems: PropTypes.string,
    className: PropTypes.string,
    justifyContent: PropTypes.string,
  }), // buttonsProps: https://material-ui.com/system/basics/#all-inclusive
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.elementType,
  containerProps: PropTypes.shape({
    className: PropTypes.string,
    component: PropTypes.elementType,
    disableGutters: PropTypes.bool,
    fixed: PropTypes.bool,
    maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  }), // containerProps: https://material-ui.com/api/container/#props
  context: PropTypes.string,
  contextProps: PropTypes.shape({
    align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
    className: PropTypes.string,
    color: PropTypes.oneOf([
      "initial",
      "inherit",
      "primary",
      "secondary",
      "textPrimary",
      "textSecondary",
      "error",
    ]), // color options are limited, use className for more styling options.
    component: PropTypes.elementType,
    variant: PropTypes.oneOf([
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle1",
      "subtitle2",
      "body1",
      "body2",
      "caption",
      "button",
      "overline",
      "srOnly",
      "inherit",
    ]), // configure typography variants in theme.
  }), // contextProps: https://material-ui.com/api/typography/#props
  heading: PropTypes.string,
  headingProps: PropTypes.shape({
    align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),
    className: PropTypes.string,
    color: PropTypes.oneOf([
      "initial",
      "inherit",
      "primary",
      "secondary",
      "textPrimary",
      "textSecondary",
      "error",
    ]), // color options are limited, use className for more styling options.
    component: PropTypes.elementType,
    variant: PropTypes.oneOf([
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle1",
      "subtitle2",
      "body1",
      "body2",
      "caption",
      "button",
      "overline",
      "srOnly",
      "inherit",
    ]), // configure typography variants in theme.
  }), // headingProps: https://material-ui.com/api/typography/#props
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    name: PropTypes.string,
  }), // image: https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#gatsby-image-props
  imageClassName: PropTypes.string,
  justifyContent: PropTypes.string,
  minHeight: PropTypes.string,
  // ...restHeroProps: https://material-ui.com/system/basics/#all-inclusive
};
