import { makeStyles } from "@material-ui/core/styles";

export const usePageServiceStyles = makeStyles(({ palette, spacing }) => ({
  categories: {
    backgroundColor: palette.grey[50],
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    margin: spacing(0, "auto", 4),
    padding: spacing(4, 0),
  },
  section: {
    margin: spacing(8, "auto"),
    position: "relative",
  },
}));
