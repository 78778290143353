import { makeStyles } from "@material-ui/core/styles";

export const useSectionMediaStyles = makeStyles(({ spacing }) => ({
  container: {
    maxWidth: 2000,
    padding: spacing(1),
  },
  section: {
    margin: "auto",
    position: "relative",
    zIndex: 1,
  },
}));
