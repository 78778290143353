import { makeStyles } from "@material-ui/core/styles";

export const useGridProjectsStyles = makeStyles(({ breakpoints, spacing }) => ({
  cell: {
    "&:first-child": {
      [breakpoints.up("sm")]: {
        gridColumn: "span 2",
      },
      [breakpoints.up("md")]: {
        gridRow: "span 2",
      },
    },
    "&:nth-child(7n)": {
      [breakpoints.up("md")]: {
        gridColumn: "span 2",
        gridRow: "span 2",
      },
      [breakpoints.up("lg")]: {
        gridColumn: "span 1",
        gridRow: "span 1",
      },
    },
    "&:nth-child(8n)": {
      [breakpoints.up("xl")]: {
        gridColumn: "span 2",
        gridRow: "span 2",
      },
    },
  },
  grid: {
    gridGap: spacing(0.5),
    margin: spacing(-6, "auto"),
  },
}));
