import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { CardProject, Grid } from "components";

import { useGridProjectsStyles } from "./GridProjects.styles";

export const GridProjects = ({ children, className, projects }) => {
  const styles = useGridProjectsStyles();

  return (
    <Grid className={classNames(styles.grid, className)}>
      {projects
        ? projects.edges.map(({ node: { fields, frontmatter, id } }) => (
            <CardProject
              className={styles.cell}
              key={id}
              path={fields.slug}
              {...frontmatter}
            />
          ))
        : children}
    </Grid>
  );
};

GridProjects.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  projects: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          fields: PropTypes.shape({
            slug: PropTypes.string,
          }),
          frontmatter: PropTypes.shape({
            category: PropTypes.string,
            description: PropTypes.string,
            image: PropTypes.shape({
              childImageSharp: PropTypes.shape({
                fluid: PropTypes.shape({
                  aspectRatio: PropTypes.number,
                  base64: PropTypes.string,
                  src: PropTypes.string,
                  srcSet: PropTypes.string,
                  sizes: PropTypes.string,
                }),
              }),
            }),
            title: PropTypes.string,
          }),
          id: PropTypes.string,
        }),
      })
    ),
  }),
};
