import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { Link as MuiLink } from "@material-ui/core";

import { useLinkStyles } from "./Link.styles";

export const Link = React.forwardRef(function Link(props, ref) {
  const styles = useLinkStyles();

  return (
    <MuiLink
      className={styles.link}
      component={GatsbyLink}
      ref={ref}
      {...props}
    />
  );
});

Link.defaultProps = {
  component: GatsbyLink,
  underline: "none",
};

Link.propTypes = {
  component: PropTypes.elementType,
  to: PropTypes.string,
  underline: PropTypes.oneOf(["none", "hover", "always"]),
}; // https://material-ui.com/api/link/#props
