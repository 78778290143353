import { makeStyles } from "@material-ui/core/styles";

export const usePageMenuStyles = makeStyles(
  ({ breakpoints, palette, spacing }) => ({
    container: {
      maxWidth: 600,
      [breakpoints.up("md")]: {
        maxWidth: 1280,
      },
    },
    heading: {
      color: palette.primary.light,
      marginBottom: spacing(1),
      marginTop: spacing(1),
    },
    section: {
      backgroundColor: palette.primary.main,
      flex: 1,
      margin: "0 auto",
      padding: spacing(6, 0),
      position: "relative",
      width: "100%",
    },
    page: {
      display: "flex",
      flex: 1,
      flexFlow: "column nowrap",
    },
  })
);
