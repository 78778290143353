import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box, Container, Typography } from "@material-ui/core";

import { useHeroMenuStyles } from "./HeroMenu.styles";

// TODO: refactor using Hero component (reference HeroHeader as example)

export const HeroMenu = ({ className, context, heading }) => {
  const styles = useHeroMenuStyles();

  return (
    <Box className={classNames(styles.hero, className)} component="header">
      <Container className={styles.container}>
        {heading && (
          <Typography className={styles.heading} component="h1" variant="h3">
            {heading}
          </Typography>
        )}
        {context && (
          <Typography className={styles.context} component="h2" variant="h6">
            {context}
          </Typography>
        )}
      </Container>
    </Box>
  );
};

HeroMenu.defaultProps = {
  className: undefined,
  context: undefined,
  heading: undefined,
};

HeroMenu.propTypes = {
  color: PropTypes.string,
  context: PropTypes.string,
  heading: PropTypes.string,
};
