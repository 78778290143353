import { makeStyles } from "@material-ui/core/styles";

export const useSectionStyles = makeStyles(({ spacing }) => ({
  image: {
    margin: "0 auto",
    width: "100%",
    "&.background": {
      height: "100%",
      left: 0,
      position: "absolute !important",
      top: 0,
      zIndex: -1,
      "&::before": {
        background:
          "linear-gradient(90deg, rgba(80, 80, 98, 0.9) 0%, rgba(80, 80, 98, 0.9) 100%)",
        content: "''",
        height: "100%",
        position: "absolute",
        right: 0,
        top: 0,
        width: "100%",
        zIndex: 1,
      },
      "&.primary, .primary &": {
        "&::before": {
          background:
            "linear-gradient(90deg, rgba(39, 56, 139, 0.9) 0%, rgba(39, 56, 139, 0.9) 100%)",
        },
      },
      "&.secondary, .secondary &": {
        "&::before": {
          background:
            "linear-gradient(90deg, rgba(15, 19, 35, 0.9) 0%, rgba(15, 19, 35, 0.9) 100%)",
        },
      },
      "&.clear": {
        "&::before": {
          content: "none",
        },
      },
    },
  },
  section: {
    margin: spacing(12, "auto"),
    position: "relative",
    "&.feature": {
      marginBottom: spacing(6),
      marginTop: spacing(-6),
    },
    "&.fluid": {
      margin: "auto",
      padding: 0,
      width: "100%",
    },
    "&.hero": {
      margin: "0 auto",
      padding: spacing(12, 0),
    },
  },
}));
