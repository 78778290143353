import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box, Container } from "@material-ui/core";

import { useSectionErrorStyles } from "./SectionError.styles";

export const SectionError = ({
  className,
  children,
  component,
  maxWidth,
  minHeight,
  ...restSectionErrorProps
}) => {
  const styles = useSectionErrorStyles();

  return (
    <Box
      component={component}
      minHeight={minHeight}
      {...restSectionErrorProps}
      className={classNames(styles.section, className)}
    >
      <Container className={styles.container} maxWidth={maxWidth}>
        {children}
      </Container>
    </Box>
  );
};

SectionError.defaultProps = {
  component: "section",
  maxWidth: "md",
  minHeight: "25vh",
};

SectionError.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  component: PropTypes.elementType,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  minHeight: PropTypes.string,
};
