import { makeStyles } from "@material-ui/core/styles";

export const useGridServicesStyles = makeStyles(({ breakpoints, spacing }) => ({
  cell: {
    [breakpoints.up("xl")]: {
      gridColumn: "span 2",
      gridRow: "span 2",
    },
    "&:first-child": {
      [breakpoints.up("sm")]: {
        gridColumn: "span 2",
        gridRow: "span 2",
      },
      [breakpoints.up("lg")]: {
        gridColumn: "span 1",
        gridRow: "span 1",
      },
      [breakpoints.up(1440)]: {
        gridColumn: "span 2",
        gridRow: "span 2",
      },
      [breakpoints.up("xl")]: {
        gridColumn: "span 4",
        gridRow: "span 4",
      },
    },
    "&:nth-child(7n)": {
      [breakpoints.up("md")]: {
        gridColumn: "span 2",
        gridRow: "span 2",
      },
      [breakpoints.up("lg")]: {
        gridColumn: "span 1",
        gridRow: "span 1",
      },
      [breakpoints.up(1440)]: {
        gridColumn: "span 2",
        gridRow: "span 2",
      },
      [breakpoints.up("xl")]: {
        gridColumn: "span 4",
        gridRow: "span 4",
      },
    },
  },
  grid: {
    gridGap: spacing(0.5),
    marginBottom: spacing(-8),
    marginTop: spacing(-8),
    [breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
    },
    [breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    },
    [breakpoints.up("xl")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    },
  },
}));
