import { makeStyles } from "@material-ui/core/styles";

export const useFormStyles = makeStyles(
  ({ breakpoints, palette, shadows, shape, spacing, typography }) => ({
    button: {
      textAlign: "center",
      width: "100%",
      [breakpoints.up("sm")]: {
        width: "auto",
        alignSelf: "flex-end",
        float: "right",
        justifySelf: "flex-end",
      },
    },
    context: {
      fontWeight: typography.fontWeightRegular,
      marginTop: spacing(2),
    },
    field: {
      marginTop: spacing(2),
      "&:last-child": {
        marginBottom: spacing(1.5),
      },
      "&.hidden": {
        display: "none !important",
      },
    },
    footer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: spacing(4, 0),
      ".frame &": {
        marginBottom: 0,
        marginTop: spacing(2),
      },
    },
    form: {
      margin: spacing(4, "auto"),
      "&.compact": {
        margin: "0 !important",
        padding: "0 !important",
      },
      "&.feature": {
        marginBottom: spacing(8),
        marginTop: spacing(-6),
        zIndex: 1,
      },
      "&.frame": {
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius,
        boxShadow: shadows[3],
        margin: "auto",
        padding: spacing(3),
        "&.feature": {
          marginBottom: spacing(-2),
          marginTop: spacing(-2),
        },
        [breakpoints.up("md")]: {
          padding: spacing(4),
        },
        [breakpoints.up("lg")]: {
          padding: spacing(5),
          "&.feature": {
            marginBottom: spacing(-8),
            marginTop: spacing(-12),
          },
        },
        [breakpoints.up("xl")]: {
          padding: spacing(6),
          "&.feature": {
            marginTop: spacing(-20),
          },
        },
      },
    },
    header: {
      marginBottom: spacing(4),
      marginTop: spacing(2),
    },
    heading: {
      fontWeight: typography.fontWeightMedium,
      ".primary &": {
        color: palette.primary.main,
      },
      ".secondary &": {
        color: palette.secondary.main,
      },
    },
    message: {
      color: palette.grey[700],
      margin: spacing(0, 0, 4),
      textAlign: "center",
      [breakpoints.up("md")]: {
        marginBottom: 0,
        textAlign: "left",
      },
      "&.error": {
        color: palette.error.main,
      },
      "&.info": {
        color: palette.info.main,
      },
      "&.success": {
        color: palette.success.main,
      },
      "&.warning": {
        color: palette.warning.main,
      },
    },
    paper: {
      borderRadius: 0,
      margin: spacing(0, -2),
      padding: spacing(4, 2),
      [breakpoints.up("sm")]: {
        margin: spacing(0, -3),
        padding: spacing(4, 3),
      },
      [breakpoints.up("md")]: {
        borderRadius: shape.borderRadius,
        margin: spacing(0, -4),
        padding: spacing(4),
      },
      [breakpoints.up("lg")]: {
        margin: spacing(0, -5),
        padding: spacing(5),
      },
      "&.clear ": {
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        padding: "0 !important",
        margin: "0 !important",
      },
    },
  })
);
