import { makeStyles } from "@material-ui/core/styles";

export const useHeroHeaderStyles = makeStyles(({ spacing }) => ({
  buttons: {
    marginTop: spacing(4),
  },
  heading: {
    marginTop: spacing(12),
  },
  hero: {
    alignItems: "flex-end",
    padding: spacing(10, 0),
  },
}));
