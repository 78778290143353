import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { StaticQuery, graphql } from "gatsby";

import { SEO } from "components";

import { LayoutMenuFooter, LayoutMenuHeader } from "./components";
import { useLayoutStyles } from "../default/Layout.styles";

export const LayoutMenu = ({ children, seo }) => {
  const styles = useLayoutStyles();

  return (
    <StaticQuery
      query={graphql`
        query LayoutMenuQuery {
          logo: file(relativePath: { eq: "assets/logo.png" }) {
            childImageSharp {
              fluid(maxHeight: 200, maxWidth: 200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          projects: allMdx(
            filter: { fileAbsolutePath: { regex: "/data/projects/" } }
          ) {
            totalCount
          }
          site {
            siteMetadata {
              contact {
                email
                phone
              }
              name
              routes {
                id
                label
                path
              }
            }
          }
        }
      `}
      render={({
        logo,
        projects: { totalCount },
        site: {
          siteMetadata: { contact, name, routes },
        },
      }) => (
        <>
          <SEO {...seo} />
          <Box className={styles.layout}>
            <LayoutMenuHeader
              brand={name}
              contact={contact}
              logo={logo}
              routes={routes}
            />
            {children}
            <LayoutMenuFooter routes={routes} totalCount={totalCount} />
          </Box>
        </>
      )}
    />
  );
};

LayoutMenu.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    logo: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
    }),
    projects: PropTypes.shape({
      totalCount: PropTypes.number,
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
        defaultDescription: PropTypes.string,
        defaultTitle: PropTypes.string,
        name: PropTypes.string,
        routes: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            path: PropTypes.string,
          })
        ),
        siteUrl: PropTypes.string,
      }),
    }),
  }),
  seo: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        name: PropTypes.string,
        property: PropTypes.string,
      })
    ),
    title: PropTypes.string,
  }),
};
