import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Grid } from "@material-ui/core";

import { Section } from "components";

import { useSectionSplitStyles } from "./SectionSplit.styles";
import { sectionPropTypes } from "../_config";

export const SectionSplit = ({
  children,
  className,
  containerProps,
  gridProps,
  left,
  leftProps,
  right,
  rightProps,
  ...restSectionProps
}) => {
  const styles = useSectionSplitStyles();

  return (
    <Section
      containerProps={{ ...containerProps }}
      {...restSectionProps}
      className={classNames(styles.section, className)}
    >
      <Grid
        container
        {...gridProps}
        className={classNames(styles.grid, gridProps.className)}
      >
        {!!left && (
          <Grid
            item
            {...leftProps}
            className={classNames(styles.gridLeft, leftProps.className)}
          >
            {left}
          </Grid>
        )}
        {!!right && (
          <Grid
            item
            {...rightProps}
            className={classNames(styles.gridRight, rightProps.className)}
          >
            {right}
          </Grid>
        )}
      </Grid>
      {children}
    </Section>
  );
};

SectionSplit.defaultProps = {
  bgcolor: "transparent",
  component: "section",
  containerProps: {
    maxWidth: "lg",
  },
  gridProps: {
    spacing: 4,
  },
  leftProps: {
    xs: 12,
    lg: 5,
    xl: 7,
  },
  rightProps: {
    xs: 12,
    lg: 7,
    xl: 5,
  },
};

SectionSplit.propTypes = {
  ...sectionPropTypes,
  gridProps: PropTypes.shape({
    className: PropTypes.string,
    spacing: PropTypes.number,
  }),
  left: PropTypes.node,
  leftProps: PropTypes.shape({
    xs: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  right: PropTypes.node,
  rightProps: PropTypes.shape({
    xs: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
};
