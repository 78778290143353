import { makeStyles } from "@material-ui/core/styles";

export const useLayoutHeaderStyles = makeStyles(({ spacing }) => ({
  button: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  container: {
    marginTop: spacing(4),
  },
}));
