import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";

import { Link } from "components";

import { useCardServiceStyles } from "./CardService.styles";

export const CardService = ({ category, className, image, path }) => {
  const styles = useCardServiceStyles();

  return (
    <Card
      className={classNames(styles.card, className)}
      elevation={1}
      variant="outlined"
    >
      <CardActionArea component={Link} to={path}>
        {image && (
          <Img
            alt={category}
            className={styles.image}
            fluid={image.childImageSharp.fluid}
          />
        )}
        <CardContent className={styles.content}>
          <Typography
            className={styles.category}
            component="h2"
            variant="subtitle1"
          >
            {category}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardService.propTypes = {
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
  }),
  path: PropTypes.string.isRequired,
};
