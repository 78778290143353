import { makeStyles } from "@material-ui/core/styles";

export const useCardProjectStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    card: {
      alignItems: "center",
      borderColor: palette.primary.dark,
      borderRadius: 0,
      display: "block",
      overflow: "hidden",
      position: "relative",
      transition: "border-color 250ms ease-in-out",
      "&:hover, &:focus": {
        borderColor: palette.secondary.main,
        textDecoration: "none",
      },
    },
    content: {
      background:
        "linear-gradient(180deg, rgba(39, 56, 139, 0) 0%, rgba(39, 56, 139, 0.5) 100%)",
      bottom: 0,
      color: palette.common.white,
      display: "flex",
      flexFlow: "column nowrap",
      height: "100%",
      justifyContent: "flex-end",
      padding: spacing(2),
      position: "absolute",
      width: "100%",
      transition: "background 250ms ease-in-out, color 250ms ease-in-out",
      "&:last-child": {
        paddingBottom: spacing(1.5),
      },
      "a:hover &, a:focus &": {
        color: palette.secondary.main,
        [breakpoints.up("lg")]: {
          background:
            "linear-gradient(180deg, rgba(39, 56, 139, 0.75) 0%, rgba(39, 56, 139, 0.25) 100%)",
        },
      },
    },
    description: {
      height: 0,
      lineHeight: 1.5,
      margin: 0,
      opacity: 0,
      overflow: "hidden",
      transition: "height 250ms ease-in-out, opacity 250ms ease-in-out",
      [breakpoints.up("lg")]: {
        "a:hover &, a:focus &": {
          height: "100%",
          opacity: 1,
        },
      },
    },
    image: {
      transition: "transform 250ms ease-in-out",
      "a:hover &, a:focus &": {
        transform: "scale3d(1.1, 1.1, 1)",
      },
    },
    title: {
      fontWeight: typography.fontWeightMedium,
      transition: "border 250ms ease-in-out",
      [breakpoints.up("lg")]: {
        "a:hover &, a:focus &": {
          borderBottom: `1px solid ${palette.secondary.main}`,
          marginBottom: spacing(1),
          paddingBottom: spacing(1),
        },
      },
    },
  })
);
