import React from "react";
import classNames from "classnames";
import { ArrowBack } from "@material-ui/icons";

import { Hero } from "components";

import { useHeroArticleStyles } from "./HeroArticle.styles";
import { heroPropTypes } from "../_config";

export const HeroArticle = ({
  buttonProps,
  buttonsProps,
  children,
  className,
  contextProps,
  headingProps,
  ...restHeroProps
}) => {
  const styles = useHeroArticleStyles();

  return (
    <Hero
      {...restHeroProps}
      buttonProps={{
        ...buttonProps,
        className: classNames(styles.button, buttonProps.className),
      }}
      buttonsProps={{
        ...buttonsProps,
        className: classNames(styles.buttons, buttonsProps.className),
      }}
      className={classNames(styles.hero, className)}
      contextProps={{
        ...contextProps,
        className: classNames(styles.context, contextProps.className),
      }}
      headingProps={{
        ...headingProps,
        className: classNames(styles.heading, headingProps.className),
      }}
    >
      {children}
    </Hero>
  );
};

HeroArticle.defaultProps = {
  alignItems: "flex-end",
  buttonProps: {
    color: "inherit",
    size: "small",
    startIcon: <ArrowBack />,
    variant: "outlined",
  },
  buttonsProps: {
    justifyContent: "flext-start",
  },
  children: null,
  className: null,
  component: "header",
  context: null,
  contextProps: {
    component: "h2",
    variant: "h6",
  },
  heading: null,
  headingProps: {
    component: "h1",
    variant: "h3",
  },
  minHeight: "40vh",
};

HeroArticle.propTypes = heroPropTypes;
