import { makeStyles } from "@material-ui/core/styles";

export const useLayoutMenuFooterStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    contact: {
      color: palette.primary.white,
      display: "block",
      "&:hover": {
        color: palette.primary.light,
        textDecoration: "none",
      },
    },
    container: {
      maxWidth: 600,
      [breakpoints.up("md")]: {
        maxWidth: 1280,
      },
    },
    chip: {
      border: `2px solid ${palette.common.white}`,
      marginRight: spacing(1.5),
      "& .MuiChip-label": {
        color: palette.common.white,
        fontWeight: typography.fontWeightBold,
      },
    },
    counter: {
      alignItems: "center",
      display: "flex",
      margin: spacing(4, 0),
      textAlign: "center",
      [breakpoints.up("md")]: {
        textAlign: "left",
      },
    },
    footer: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
    icon: {
      marginLeft: spacing(1),
    },
    item: {
      padding: spacing(1.5, 0),
    },
    link: {
      alignItems: "center",
      color: palette.primary.light,
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
      transition: "color 200ms ease-in-out",
      "&:hover": {
        color: palette.common.white,
        textDecoration: "none",
      },
    },
    nav: {
      display: "flex",
      flexFlow: "column nowrap",
      padding: spacing(0, 0, 4),
    },
  })
);
