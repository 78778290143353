import { makeStyles } from "@material-ui/core/styles";

export const useListServiceCategoriesStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    category: {
      fontWeight: typography.fontWeightMedium,
      lineHeight: 1.25,
      transition: "letter-spacing 200ms ease-in-out",
      [breakpoints.up("lg")]: {
        fontSize: "150%",
      },
      "a:hover &": {
        letterSpacing: 1,
      },
    },
    img: {
      borderRadius: "50%",
      boxShadow: "0 0 1px 3px white",
      margin: spacing(0, 3, 0, 2),
      minHeight: 60,
      minWidth: 60,
      transition: "box-shadow 200ms ease-in-out, transform 200ms ease-in-out",
      [breakpoints.up("lg")]: {
        minHeight: 80,
        minWidth: 80,
      },
      "a:hover &": {
        boxShadow: `0 0 1px 3px ${palette.secondary.main}`,
        transform: "scale(1.05)",
      },
    },
    item: {
      alignItems: "stretch",
      flexFlow: "column nowrap",
      padding: spacing(3, 0),
      [breakpoints.up("sm")]: {
        flex: "1 50%",
      },
      [breakpoints.up("md")]: {
        flex: "1 33.3333%",
      },
      [breakpoints.up("lg")]: {
        flex: "1 100%",
      },
      [breakpoints.up("xl")]: {
        flex: "1 50%",
      },
    },
    link: {
      alignItems: "center",
      color: palette.common.white,
      display: "flex",
      textDecoration: "none",
      transition: "color 200ms ease-in-out",
      "&:hover": {
        color: palette.secondary.main,
        textDecoration: "none",
      },
    },
    list: {
      alignItems: "flex-start",
      display: "flex",
      flexFlow: "row wrap",
      margin: "0 auto",
      padding: 0,
      [breakpoints.up("lg")]: {
        marginRight: 0,
      },
    },
  })
);
