import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import classNames from "classnames";
import { Box, Button, Container, Typography } from "@material-ui/core";

import { Link } from "components";

import { useHeroProjectStyles } from "./HeroProject.styles";

// TODO:
// - refactor using Hero component (reference HeroHeader as example)
// - update to universal component (used by blog and projects, maybe services)

export const HeroProject = ({
  bgcolor,
  buttons,
  children,
  className,
  color,
  context,
  heading,
  image,
  maxWidth,
  minHeight,
}) => {
  const styles = useHeroProjectStyles();

  return (
    <Box
      bgcolor={bgcolor}
      className={classNames(styles.hero, className)}
      color={color}
      component="header"
      minHeight={minHeight}
    >
      {image && (
        <Img
          alt={image.name || heading}
          className={styles.image}
          fluid={image.childImageSharp.fluid}
        />
      )}
      <Container className={styles.container} maxWidth={maxWidth}>
        {heading && (
          <Typography className={styles.heading} component="h1" variant="h3">
            {heading}
          </Typography>
        )}
        {context && (
          <Typography className={styles.context} component="h2" variant="h6">
            {context}
          </Typography>
        )}
        {children}
        {buttons && buttons.length && (
          <Box className={styles.buttons}>
            {buttons.map((button) => (
              <Button
                activeClassName="active"
                className={styles.button}
                component={Link}
                key={button.id}
                to={button.path}
                size="small"
                variant="outlined"
              >
                {button.label}
              </Button>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};

HeroProject.defaultProps = {
  bgcolor: "primary.main",
  buttons: null,
  color: "common.white",
  children: null,
  className: null,
  context: null,
  heading: null,
  image: null,
  maxWidth: "lg",
  minHeight: "40vh",
};

HeroProject.propTypes = {
  bgcolor: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  context: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        sizes: PropTypes.string,
      }),
    }),
    name: PropTypes.string,
  }),
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
};
