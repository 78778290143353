import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import palette from "./palette";
import typography from "./typography";

// Matetial UI Custom Theming: https://material-ui.com/customization/theming/
// Material UI Default Theme: https://material-ui.com/customization/default-theme/

let theme = createMuiTheme({
  palette,
  typography,
});

// responsiveFontSizes API - https://material-ui.com/customization/theming/#responsivefontsizes-theme-options-theme
theme = responsiveFontSizes(theme);

export default theme;
