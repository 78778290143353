import { makeStyles } from "@material-ui/core/styles";

// TODO: create mixins for some of these styles
// - link needs a mixin
// - paper needs a mixin
// - heading needs a mixin

export const useArticleStyles = makeStyles(
  ({ breakpoints, palette, shape, spacing }) => ({
    article: {
      "& .heading": {
        margin: spacing(6, 0, 4),
      },
      "& .title": {
        margin: spacing(18, 0, 9),
      },
      "& .link": {
        color: palette.text.secondary,
        transition: "color 250ms ease-in-out",
        "&:hover": {
          color: palette.primary.main,
        },
      },
      "& .nested-list": {
        paddingLeft: spacing(2),
      },
      "& .paper": {
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius,
        padding: spacing(2),
      },
      "& .gatsby-resp-image-wrapper": {
        margin: `${spacing(4, -2)} !important`,
        [breakpoints.up("sm")]: {
          margin: `${spacing(4, -3)} !important`,
        },
        [breakpoints.up("md")]: {
          margin: `${spacing(4, -4)} !important`,
        },
        [breakpoints.up("lg")]: {
          margin: `${spacing(4, -5)} !important`,
        },
        [breakpoints.up("xl")]: {
          margin: `${spacing(4, -6)} !important`,
        },
      },
    },
  })
);
