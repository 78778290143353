import { makeStyles } from "@material-ui/core/styles";

export const useHeroServiceStyles = makeStyles(({ palette, spacing }) => ({
  context: {
    margin: spacing(2, 0),
    maxWidth: 800,
  },
  heading: {
    marginTop: spacing(12),
    maxWidth: 800,
  },
  hero: {
    backgroundColor: palette.secondary.main,
    padding: spacing(10, 0, 14),
  },
}));
