import { makeStyles } from "@material-ui/core/styles";

export const useHeroArticleStyles = makeStyles(({ palette, spacing }) => ({
  buttons: {
    marginBottom: spacing(1),
    marginTop: spacing(3),
  },
  container: {
    zIndex: 1,
  },
  context: {
    color: palette.primary.light,
    marginBottom: spacing(1),
    marginTop: spacing(1),
    ".primary &": {
      color: palette.common.white,
    },
    ".secondary &": {
      color: palette.secondary.dark,
    },
  },
  heading: {
    color: palette.primary.light,
    marginBottom: spacing(1),
    marginTop: spacing(8),
    ".primary &": {
      color: palette.common.white,
    },
    ".secondary &": {
      color: palette.secondary.dark,
    },
  },
  hero: {
    alignItems: "flex-end",
    backgroundColor: palette.grey[700],
    color: palette.primary.light,
    display: "flex",
    overflow: "hidden",
    padding: spacing(10, 0),
    position: "relative",
    "&.primary": {
      backgroundColor: palette.primary.main,
      color: palette.common.white,
    },
    "&.secondary": {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.dark,
    },
  },
}));
